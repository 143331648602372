<template lang="pug">
  .row
    .immutable-field
      FormFieldLabel.label(:title="$t(title)")
      span {{ text }}
</template>

<script>
  export default {
    components: {
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel")
    },

    props: {
      title: {
        type: String,
        required: true
      },
      text: {
        type: String,
        default: ""
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .row
    ::v-deep
      .label
        color: $default-gray

    .immutable-field
      display: flex
      flex-direction: column

      .label
        margin-bottom: 7px
</style>
